.mobile-sidebar {
    background-color: #F4F5FA;
    height: 100vh;
    width: 220px;
}

.mobile-sidebar-log-section img {
    height: 50px;
    width: 50px;
}



.mobile-sidebar .mobile-sidebar-log-section {
    display: flex;
    align-items: center;
    width: 18%;
    background-color: #F4F5FA;
    padding: 8px;
}


.mobile-sidebar .log-section h3 {
    text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
    .sidebarList {
        margin-top: 0;
    }

}