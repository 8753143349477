.user-table-container {
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 6px;
    padding: 10px;
    background-color: white;
    margin: 20px 0;
}

.user-table-container .userlisting-word {
    margin: 5px 10px;
    color: black;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-table-container .userlisting-word button {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    background-image: linear-gradient(98deg, #9cc177, #6ba134 94%);
    color: white;
    font-weight: bold;
    font-size: .9rem;
    cursor: pointer;
}

.profile-image-table {
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-action-buttons{
    display: flex;
    align-items: center;
}