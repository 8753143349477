
body {
    padding: 0;
    margin: 0;
    color: rgba(58, 53, 65, 0.87);
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}

main {
    display: flex;
}


:root {
    --primary: #8fb569;
    --secondary: rgba(58, 53, 65, 0.87)
}

ul,
p {
    margin: 0;
    padding: 0;
}

.react-link {
    text-decoration: none;
    color: #8fb569;
}

h4,
h3,
h2,
h1 {
    margin: 0;
    padding: 0;
}

.loader-position {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    /* height: 100vh; */
}



