

.edit-user-container-view-cient-wrapper {
    display: flex;
    flex-direction: column;
}




.edit-user-container-view-cient {
    display: flex;
    align-items: start;
    gap: 10px;
}

.edit-user-account-details {
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 4px;
    padding: 13px;
    background-color: white;
    margin: 20px 0;
    flex: 1;
}

.edit-user-account-details-isloading {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 4px;
    padding: 13px;
    background-color: white;
    margin: 20px 0;
    flex: 1;
}

.edit-user-account-inputs-client {
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 4px;
    padding: 10px;
    background-color: white;
    margin: 20px 0;
    flex: 2;
}



.edit-user-account-details .user-profile-photo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.edit-user-account-details .user-profile-photo p {
    font-weight: bold;
    color: black;
}

.edit-user-container-view-cient .edit-user-account-detail p {
    font-weight: bold;
    color: black;
}

.edit-user-container-view-cient .edit-user-account-detail .details {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 25px 0;
}

.edit-user-container-view-cient .edit-user-account-detail .details span {
    color: black;
}










.certificate-div {
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .edit-user-container-view-cient {
        flex-direction: column;
    }
}