.edit-user-container-wrapper {
    position: relative;
}

.date-time-picker-container {
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 4px;
    padding: 13px;
    background-color: white;
}


.edit-user-container {
    display: flex;
    align-items: start;
    gap: 10px;

}


.edit-user-account-details-isloading {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 4px;
    padding: 13px;
    background-color: white;
    margin: 20px 0;
    flex: 1;
}

.edit-user-account-details {
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 4px;
    padding: 13px;
    background-color: white;
    margin: 20px 0;
    flex: 1;
    position: sticky;
    top: 0;

}


.edit-user-account-inputs {
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 4px;
    padding: 10px;
    background-color: white;
    margin: 20px 0;
    flex: 2;
}






.edit-user-account-inputs-isloading {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 4px;
    padding: 10px;
    background-color: white;
    margin: 20px 0;
    flex: 2;
}

.edit-user-account-details .user-profile-photo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.edit-user-account-details .user-profile-photo p {
    font-weight: bold;
    color: black;
}

.edit-user-container .edit-user-account-detail p {
    font-weight: bold;
    color: black;
}

.edit-user-container .edit-user-account-detail .details {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 25px 0;
}

.edit-user-container .edit-user-account-detail .details span {
    color: black;
}



.edit-user-account-inputs .edit-user-inputs-div-containers {
    display: flex;
}

.edit-user-account-inputs .edit-user-inputs-div-containers .edit-user-inputs-div1 {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 2;
}

.edit-user-account-inputs .edit-user-inputs-div-containers .edit-user-inputs-div2 {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.edit-user-account-inputs .edit-word {
    padding: 10px;
}

.edit-user-account-inputs .edit-user-inputs-div-containers .edit-user-inputs-div2 .profileImage-edit {
    width: 100px;
    height: 100px;
}



.upload-edit-image {
    background-color: rgb(228, 228, 228);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
}

.profileImageContainer {
    position: relative;
}



.profileImage-edit-button {
    width: 30px;
    height: 30px;
    background-color: rgb(205, 201, 201);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -5px;
    left: 60%;

}

.update-button-edit-user {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    background-image: linear-gradient(98deg, #9cc177, #6ba134 94%);
    color: white;
    font-weight: bold;
    font-size: .9rem;
    cursor: pointer;
}




.update-button-edit-button-enabled {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    background-image: linear-gradient(98deg, #9cc177, #6ba134 94%);
    color: white;
    font-weight: bold;
    font-size: .9rem;
    cursor: pointer;
}

.update-button-edit-button-disabled {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: rgb(169, 166, 166);
    color: white;
    font-weight: bold;
    font-size: .9rem;
    cursor: not-allowed;
}

.certificate-div {
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .edit-user-container {
        flex-direction: column;
    }

    .edit-user-account-details {
        width: 100%;
    }

    .edit-user-account-inputs{
        width: 100%;
    }

    .edit-user-account-inputs-isloading{
        width: 100%;
    }

    .edit-user-account-details-isloading{
        width: 100%;
    }
}