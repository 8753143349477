.admin-header {
    padding: 10px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.admin-header-items {
    display: flex;
    align-items: center;
    justify-content: space-around;

}

.userImage-avatar {
    position: relative;
}

.userImage-avatars{
    position: relative;
}



.active-online {
    height: 10px;
    width: 10px;
    background-color: rgb(79, 181, 79);
    border-radius: 50%;
    position: absolute;
    bottom: 9px;
    right: 8px;
}

.active-onlines {
    height: 10px;
    width: 10px;
    background-color: rgb(79, 181, 79);
    border-radius: 50%;
    position: absolute;
    bottom: 9px;
    right: 8px;
}

.heder-right-items{
    display: none;
}


@media only screen and (max-width: 600px) {
    .heder-right-items{
        display: block;
    }


    .admin-header {
        justify-content: space-between;
    }
}