.data-table {
    margin: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    margin: 10px 10px;
    display: flex;
    justify-content: end;
}

.slot-date-time {
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.custom-shift-table {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}

.shift-userimage-view {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.cutom-table-pagination {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 8px 0;
    justify-content: end;
}

.cutom-table-pagination .pagination-number-button {
    border: none;
    padding: 10px 15px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    background-color: #8fb569;
}