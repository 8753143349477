.shit-request-div .shift-details {
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 4px;
    padding: 13px;
    background-color: white;
    margin: 20px 0;
}


.shift-details-request-row {
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 15px;
}

.shift-request-customer-details {
    /* box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1); */
    /* border-radius: 5px; */
    background-color: white;
    display: flex;
    flex-direction: column;
}

.shift-request-customer-details h4 {
    margin: 20px;
    /* text-align: center; */
}

.shift-request-customer-details .inner-request-details {
    background-color: white;
    padding: 10px;
    border-bottom: 1px solid rgb(219, 218, 218);
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.shift-request-customer-details .inner-request-details {
    display: flex;
    align-items: center;

}

.shift-request-customer-details .inner-request-details .avatar-row {
    display: flex;
    align-items: center;
    gap: 20px;
}

.shift-request-customer-details .inner-request-details .avatar-row .name {
    font-weight: bold;
    font-size: medium;
}



.shift-request-customer-details .inner-request-details .avatar-row .details-row .details-row-wrapper {
    display: flex;
    align-items: center;
    gap: 7px;
}

.shift-request-customer-details .inner-request-details .avatar-row .details-row .details-row-wrapper-column {
    display: flex;

    gap: 20px;
    margin: 10px 0;
}

.accept-reject-button-row {
    margin-right: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.accept-reject-button-row .accept-button {
    border: none;
    padding: 10px 30px;
    color: white;
    border-radius: 5px;
    background-color: #41c764;
    cursor: pointer;
}


.accept-reject-button-row .accept-button-no-cursor {
    border: none;
    padding: 10px 30px;
    color: white;
    border-radius: 5px;
    background-color: #41c764;
}

.accept-reject-button-row .reject-button {
    border: none;
    padding: 10px 30px;
    color: white;
    border-radius: 5px;
    background-color: #FF6666;
    cursor: pointer;
}

.accept-reject-button-row .reject-button-no-cursor {
    border: none;
    padding: 10px 30px;
    color: white;
    border-radius: 5px;
    background-color: #FF6666;
}

@media only screen and (max-width: 600px) {
    .accept-reject-button-row {
        gap: 8px;
        display: flex;
        flex-direction: column;
    }

    .accept-reject-button-row .accept-button {
        padding: 5px 10px;
    }

    .accept-reject-button-row .reject-button {
        padding: 5px 13px;
    }

    .accept-reject-button-row .accept-button-no-cursor {
        padding: 5px 10px;
        margin-left: 5px;
    }

    .accept-reject-button-row .reject-button-no-cursor {
        padding: 5px 10px;
        margin-left: 5px;
    }

    .shift-request-customer-details .inner-request-details .avatar-row p{
        font-size: small;
    }
}