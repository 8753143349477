.sidenav {
    height: 100%;
    width: 18%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #F4F5FA;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for WebKit browsers */
.sidenav::-webkit-scrollbar {
    width: 0.5em;
}

.sidenav::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.sidenav .log-section {
    display: flex;
    align-items: center;
    width: 17%;
    position: fixed;
    background-color: #F4F5FA;
    padding: 8px;
}

.sidenav .log-section img {
    width: 60px;
}

.sidenav .log-section h3 {
    text-transform: uppercase;
}





.sidebarList {
    list-style: none;
    margin-top: 80px;

}

.sidebarlist-items-active {
    box-shadow: 0px 4px 8px -4px rgba(58, 53, 65, 0.42);
    background-image: linear-gradient(98deg, #c1e2a0, #6ba134 94%);
    padding: 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 85%;
    cursor: pointer;
    color: white;
    margin: 8px 0;
}


.sidebarlist-items {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 85%;
    cursor: pointer;
    color: black;
    margin: 8px 0;
}

.sidebarlist-items:hover {
    background-color: rgb(234, 234, 234);
}

.sidebarList .divider {
    font-size: small;
    margin: 20px 0;
}

.sidebarlist-items-active .side-bar-span {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
}

.sidebarlist-items .side-bar-span {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
}



@media only screen and (max-width: 600px) {
    .sidenav {
        display: none;
    }
}