.content-area-wrapper {
    margin-left: 18%;
    background-color: #F4F5FA;
    padding: 0 20px 60px 20px;
    width: 82%;
    height: 100%;
    min-height: 100vh;
}



@media only screen and (max-width: 600px) {
    .content-area-wrapper {
        margin-left: 0;
        width: 100%;
        overflow-y: hidden;
    }
}