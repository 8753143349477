.shift-form-container {
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    border-radius: 6px;
    padding: 10px;
    background-color: white;
    margin: 20px 0;
}

.create-shift-text {
    text-transform: uppercase;
    text-align: center;

}


.shift-inputs {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 20px;
}


.create-shift-button {
    display: flex;
    justify-content: end;
    margin: 20px 20px;

}


.create-shift-button .button-enabled {
    border: none;
    padding: 13px 40px;
    border-radius: 5px;
    background-image: linear-gradient(98deg, #9cc177, #6ba134 94%);
    color: white;
    font-weight: bold;
    font-size: .9rem;
    cursor: pointer;
}


.create-shift-button .button-disabled {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: rgb(169, 166, 166);
    color: white;
    font-weight: bold;
    font-size: .9rem;
    cursor: not-allowed;
}










